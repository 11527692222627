import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import Vant from 'vant'
import 'vant/lib/index.css';

localStorage.setItem("language","ch");
import {time_format} from './assets/tool/index'
// 时间转换
Vue.prototype.time_format = time_format;

import pcormove from './assets/tool/pcormove'; // 判断pc还是移动
import msg from './assets/tool/msg'; //消息提示框

// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//   ak: 'tmVNmCGrmCwHrRhXLpkVPLk5GSZnblbj' // 替换成你自己的百度地图开发密钥
// })

Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false

Vue.prototype.bus = new Vue();
Vue.prototype.pcormove = pcormove._isMobile();
Vue.prototype.msg = msg.msg;

const lang = localStorage.getItem('language')||"ch";
Vue.prototype.lang = lang;

// store.dispatch('login/RouterAuth') //路由守卫

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

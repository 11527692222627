import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/layout.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: { noAuth: true },
  //   component: () => import('@/views/login/login.vue')
  // },
  {
    path: '/',//home
    component: layout,
    children: [{
      path: '/',
      name: 'home',
      component: () => import('@/views/home/home.vue'),
    }]
  },{
    path: '/sign_in',//签到页面
    component: layout,
    children: [{
      path: '/',
      name: 'sign_in',
      component: () => import('@/views/sign_in/sign_in.vue'),
    }]
  },{
    path: '/sign_in2',//签到页面
    component: layout,
    children: [{
      path: '/',
      name: 'sign_in2',
      component: () => import('@/views/sign_in/sign_in2.vue'),
    }]
  },{
    path: '/sign_in3',//签到页面
    component: layout,
    children: [{
      path: '/',
      name: 'sign_in3',
      component: () => import('@/views/sign_in/sign_in3.vue'),
    }]
  },{
    path: '/sign_in4',//签到页面
    component: layout,
    children: [{
      path: '/',
      name: 'sign_in4',
      component: () => import('@/views/sign_in/sign_in4.vue'),
    }]
  },{
    path: '/sign_in5',//签到页面
    component: layout,
    children: [{
      path: '/',
      name: 'sign_in5',
      component: () => import('@/views/sign_in/sign_in5.vue'),
    }]
  },
  
  {
    path: '*',
    name: '404',
    meta: { noAuth: true },
    component: () => import('@/views/404.vue')
  }
  
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('hrd_token');
//     const userid = localStorage.getItem('hrd_userid');
//   if (!to.meta.noAuth) {
//     if (!token || !userid) {
//       return next({ name: 'login' });
//     }else{
//       return next();
//     }
//   }else{
//     if (token && userid) {
//       return next({ name: 'home' });
//     }else{
//       return next();
//     }
//   }
//   // next();
// });

export default router